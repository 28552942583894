import useJwt from "@/libs/auth/jwt/useJwt";

const axios = useJwt.axiosIns;

export const getHours48Params = (params) =>
    axios.get("/api/lfgc5/params/hours-48-params", {params});

export const getHours48Energy = (params) =>
    axios.get("/api/lfgc5/params/hours-48-energy", {params});

export const getRealTimeEnergy = () =>
    axios.get("/api/lfgc5/params/real-time-energy");
