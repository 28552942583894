<template>
    <div>
        <div
            class="w-100 position-absolute"
            style="
                background-image: url(/images/bg_lfgc1.jpg);
                background-position: center;
                background-size: cover;
                z-index: -1;
                margin-left: -2rem;
                margin-top: calc(-2rem - 81px);
                height: 100vh;
            "
        ></div>

        <!-- top -->
        <b-card no-body class="text-center font-title headerTitle py-25">
            <h1>YOUNG PARK</h1>
            <h2>利丰广场5号楼</h2>
        </b-card>

        <b-row>
            <!-- 性能指标 -->
            <b-col cols="3">
                <b-card class="cardBackdrop">
                    <b-card-title>
                        性能指标
                        <small>Performance Index</small>
                    </b-card-title>
                    <b-card-text
                        class="d-flex flex-column justify-content-between"
                        style="height: calc(100% - 3rem)"
                    >
                        <b-row>
                            <b-col class="d-flex align-items-center" cols="6">
                                <b-avatar
                                    class="mr-1"
                                    rounded
                                    variant="light-info"
                                >
                                    <b-icon-building
                                        font-scale="1.5"
                                    ></b-icon-building>
                                </b-avatar>

                                <div>
                                    <span>建筑面积</span><br />
                                    <span class="text-info">325.57</span>
                                    <small>&nbsp;m<sup>2</sup></small>
                                </div>
                            </b-col>

                            <b-col class="d-flex align-items-center" cols="6">
                                <b-avatar
                                    class="mr-1"
                                    rounded
                                    variant="light-warning"
                                >
                                    <b-icon-sun-fill
                                        font-scale="1.5"
                                    ></b-icon-sun-fill>
                                </b-avatar>
                                <div>
                                    <span>光伏容量</span><br />
                                    <span class="text-warning">16.53</span>
                                    <small>&nbsp;kWp</small>
                                </div>
                            </b-col>

                            <b-col
                                class="d-flex align-items-center mt-1"
                                cols="6"
                            >
                                <b-avatar
                                    class="mr-1"
                                    rounded
                                    variant="light-danger"
                                >
                                    <b-icon-house-door-fill
                                        font-scale="1.5"
                                    ></b-icon-house-door-fill>
                                </b-avatar>
                                <div>
                                    <span>建筑能耗综合值</span><br />
                                    <span class="text-danger">-1.50</span>
                                    <small
                                        >&nbsp;kWh/(m<sup>2</sup>&sdot;a)</small
                                    >
                                </div>
                            </b-col>

                            <b-col
                                class="d-flex align-items-center mt-1"
                                cols="6"
                            >
                                <b-avatar
                                    class="mr-1"
                                    rounded
                                    variant="light-info"
                                >
                                    <b-icon-battery-charging
                                        font-scale="1.5"
                                    ></b-icon-battery-charging>
                                </b-avatar>
                                <div>
                                    <span>可再生能源产能</span><br />
                                    <span class="text-info">48.80</span>
                                    <small
                                        >&nbsp;kWh/(m<sup>2</sup>&sdot;a)</small
                                    >
                                </div>
                            </b-col>

                            <b-col
                                class="d-flex align-items-center mt-1"
                                cols="6"
                            >
                                <b-avatar
                                    class="mr-1"
                                    rounded
                                    variant="light-info"
                                >
                                    <b-icon-bricks
                                        font-scale="1.5"
                                    ></b-icon-bricks>
                                </b-avatar>
                                <div>
                                    <span>外墙传热系数</span><br />
                                    <span class="text-info">0.40</span>
                                    <small>&nbsp;W/m<sup>2</sup>&sdot;K</small>
                                </div>
                            </b-col>

                            <b-col
                                class="d-flex align-items-center mt-1"
                                cols="6"
                            >
                                <b-avatar
                                    class="mr-1"
                                    rounded
                                    variant="light-primary"
                                >
                                    <b-icon-square-half
                                        font-scale="1.5"
                                    ></b-icon-square-half>
                                </b-avatar>
                                <div>
                                    <span>外窗传热系数</span><br />
                                    <span class="text-primary">1.80</span>
                                    <small>&nbsp;W/m<sup>2</sup>&sdot;K</small>
                                </div>
                            </b-col>

                            <b-col
                                class="d-flex align-items-center mt-1"
                                cols="6"
                            >
                                <b-avatar
                                    class="mr-1"
                                    rounded
                                    variant="light-success"
                                >
                                    <b-icon-tablet-fill
                                        font-scale="1.5"
                                    ></b-icon-tablet-fill>
                                </b-avatar>
                                <div>
                                    <span>多联机性能系数</span><br />
                                    <span class="text-success">6.0</span>
                                    <small>&nbsp;IPLV (C)</small>
                                </div>
                            </b-col>

                            <b-col
                                class="d-flex align-items-center mt-1"
                                cols="6"
                            >
                                <b-avatar
                                    class="mr-1"
                                    rounded
                                    variant="light-warning"
                                >
                                    <b-icon-file-break-fill
                                        font-scale="1.5"
                                    ></b-icon-file-break-fill>
                                </b-avatar>
                                <div>
                                    <span>多联机效率等级</span><br />
                                    <span class="text-warning">4.5</span>
                                    <small>&nbsp;APF</small>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>

                <!-- <div class="divider">
                    <div class="divider-text">今日能效</div>
                </div> -->
                <b-card class="cardBackdrop">
                    <b-card-title>
                        今日能效
                        <small>Energy Today</small>

                        <small class="float-right" v-show="latest_time"
                            >截至
                            <b-badge variant="light-primary" class="ml-25"
                                >{{ latest_time }}
                            </b-badge>
                        </small>
                    </b-card-title>
                    <b-card-text
                        class="d-flex flex-column justify-content-between"
                    >
                        <b-row>
                            <b-col cols="4">
                                <b-alert show variant="danger" class="mb-0">
                                    <div class="alert-body text-center py-2">
                                        <b-icon-lightning-fill
                                            class="my-50"
                                            font-scale="1.5"
                                        />
                                        <p class="mb-1">耗电量</p>
                                        <div
                                            class="font-digit text-danger"
                                            style="font-size: 2rem"
                                        >
                                            {{ total_cost }}
                                        </div>
                                        <div class="mb-25">
                                            <label>kWh</label>
                                        </div>
                                        <b-badge variant="light-danger"
                                            >{{ increase_total_cost }}
                                        </b-badge>
                                    </div>
                                </b-alert>
                            </b-col>

                            <b-col cols="4">
                                <b-alert show variant="success" class="mb-0">
                                    <div class="alert-body text-center py-2">
                                        <b-icon-sunrise-fill
                                            class="my-50"
                                            font-scale="1.5"
                                        />
                                        <p class="mb-1">发电量</p>
                                        <div
                                            class="font-digit text-success"
                                            style="font-size: 2rem"
                                        >
                                            {{ total_generate }}
                                        </div>
                                        <div class="mb-25">
                                            <label>kWh</label>
                                        </div>
                                        <b-badge variant="light-success"
                                            >{{ increase_total_generate }}
                                        </b-badge>
                                    </div>
                                </b-alert>
                            </b-col>

                            <b-col cols="4">
                                <b-alert show variant="warning" class="mb-0">
                                    <div class="alert-body text-center py-2">
                                        <b-icon-cloud-fog2-fill
                                            class="my-50"
                                            font-scale="1.5"
                                        />
                                        <p class="mb-1">碳排放</p>
                                        <div
                                            class="font-digit text-warning"
                                            style="font-size: 2rem"
                                        >
                                            {{ total_co2 }}
                                        </div>
                                        <div class="mb-25">
                                            <label>kgCO<sub>2</sub></label>
                                        </div>
                                        <b-badge variant="light-warning"
                                            >{{ increase_total_co2 }}
                                        </b-badge>
                                    </div>
                                </b-alert>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>

            <!-- 建筑模型 -->
            <b-col cols="6">
                <b-row>
                    <b-col cols="6" offset="6">
                        <b-carousel
                            id="carousel-1"
                            v-model="slide"
                            :interval="3000"
                            @sliding-start="onSlideStart"
                            @sliding-end="onSlideEnd"
                        >
                            <b-carousel-slide class="carouselSlide">
                                <b-avatar
                                    rounded
                                    size="48"
                                    variant="light-danger"
                                >
                                    <b-icon-shield-fill-check
                                        font-scale="2"
                                    ></b-icon-shield-fill-check>
                                </b-avatar>

                                <p class="my-1">
                                    建筑能效<br />
                                    <small>Building Energy Performance</small>
                                </p>
                                <h3 class="my-0">
                                    <b-badge variant="light-danger"
                                        >优
                                    </b-badge>
                                </h3>
                            </b-carousel-slide>

                            <b-carousel-slide class="carouselSlide">
                                <b-avatar
                                    rounded
                                    size="48"
                                    variant="light-success"
                                >
                                    <b-icon-emoji-smile-fill
                                        font-scale="2"
                                    ></b-icon-emoji-smile-fill>
                                </b-avatar>

                                <p class="my-1">
                                    空气品质<br /><small
                                        >Indoor Air Quality</small
                                    >
                                </p>
                                <h3 class="my-0">
                                    <b-badge variant="light-success"
                                        >优
                                    </b-badge>
                                </h3>
                            </b-carousel-slide>

                            <b-carousel-slide class="carouselSlide">
                                <b-avatar
                                    rounded
                                    size="48"
                                    variant="light-warning"
                                >
                                    <b-icon-hand-thumbs-up-fill
                                        font-scale="2"
                                    ></b-icon-hand-thumbs-up-fill>
                                </b-avatar>

                                <p class="my-1">
                                    设备能效<br /><small
                                        >Equipment Efficiency</small
                                    >
                                </p>
                                <h3 class="my-0">
                                    <b-badge variant="light-warning"
                                        >优
                                    </b-badge>
                                </h3>
                            </b-carousel-slide>

                            <b-carousel-slide class="carouselSlide">
                                <p>
                                    设计咨询及运行监测<br />
                                    <small>Consulted and Monitored by</small>
                                </p>
                                <b-img
                                    :src="
                                        require('@/assets/images/logo/cabr4.png')
                                    "
                                    fluid
                                    style="height: 2.5rem"
                                />
                                <br />
                                <b-badge variant="light-secondary" class="mt-1"
                                    >2023-11
                                </b-badge>
                            </b-carousel-slide>
                        </b-carousel>
                    </b-col>
                </b-row>
            </b-col>

            <!-- 标识状态 -->
            <b-col cols="3">
                <b-card class="cardBackdrop">
                    <b-card-title>
                        标识认证
                        <small>Certificate Label</small>
                    </b-card-title>

                    <b-card-text
                        class="d-flex flex-column justify-content-between"
                        style="height: calc(100% - 3rem)"
                    >
                        <b-row>
                            <b-col class="text-center" cols="6">
                                <b-img
                                    :src="
                                        require('@/assets/images/buildings/label_nearly_zero.png')
                                    "
                                    fluid
                                    style="height: 8rem"
                                />

                                <h3
                                    class="font-weight-bolder mt-2"
                                    style="letter-spacing: 0.5rem"
                                >
                                    零能耗建筑
                                </h3>

                                <small
                                    >Zero Energy Building<br />
                                    （设计阶段）</small
                                >
                            </b-col>

                            <b-col cols="6">
                                <p>
                                    证书编号<br />
                                    <b-badge variant="light-success"
                                        >JLNH202307021
                                    </b-badge>
                                </p>
                                <p>
                                    颁证机构<br />
                                    <b-badge variant="light-success"
                                        >中国建筑节能协会
                                    </b-badge>
                                </p>
                                <p>
                                    认证日期<br />
                                    <b-badge variant="light-success"
                                        >2023年7月30日
                                    </b-badge>
                                </p>
                                <p>
                                    项目类型<br />
                                    <b-badge variant="light-success"
                                        >公共建筑
                                    </b-badge>
                                </p>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>

                <b-card class="cardBackdrop">
                    <b-card-title>
                        评价指标
                        <small>Evaluation Index</small>
                    </b-card-title>
                    <b-card-text>
                        <b-row>
                            <b-col
                                cols="12"
                                class="d-flex align-items-center justify-content-between"
                            >
                                <div class="d-flex align-items-center">
                                    <b-avatar
                                        rounded
                                        variant="light-success"
                                        class="mr-1"
                                        size="36"
                                    >
                                        <b-icon-graph-down
                                            scale="1.5"
                                        ></b-icon-graph-down>
                                    </b-avatar>
                                    <div>
                                        建筑综合节能率<br />
                                        <span class="h2 text-success">102</span>
                                        <span>&nbsp;%</span>
                                    </div>
                                </div>
                                <div class="text-right">
                                    <small>优于国标要求</small><br />
                                    <b-badge variant="light-success"
                                        >≥60%
                                    </b-badge>
                                </div>
                            </b-col>

                            <b-col
                                cols="12"
                                class="d-flex align-items-center justify-content-between mt-1"
                            >
                                <div class="d-flex align-items-center">
                                    <b-avatar
                                        rounded
                                        variant="light-danger"
                                        class="mr-1"
                                        size="36"
                                    >
                                        <b-icon-shield-check
                                            scale="1.5"
                                        ></b-icon-shield-check>
                                    </b-avatar>
                                    <div>
                                        建筑本体节能率<br />
                                        <span class="h2 text-danger">39</span>
                                        <span>&nbsp;%</span>
                                    </div>
                                </div>
                                <div class="text-right">
                                    <small>优于国标要求</small><br />
                                    <b-badge variant="light-danger"
                                        >≥20%
                                    </b-badge>
                                </div>
                            </b-col>

                            <b-col
                                cols="12"
                                class="d-flex align-items-center justify-content-between mt-1"
                            >
                                <div class="d-flex align-items-center">
                                    <b-avatar
                                        rounded
                                        variant="light-warning"
                                        class="mr-1"
                                        size="36"
                                    >
                                        <b-icon-arrow-clockwise
                                            scale="1.5"
                                        ></b-icon-arrow-clockwise>
                                    </b-avatar>
                                    <div>
                                        可再生能源利用率<br />
                                        <span class="h2 text-warning">103</span>
                                        <span>&nbsp;%</span>
                                    </div>
                                </div>
                                <div class="text-right">
                                    <small>优于国标要求</small><br />
                                    <b-badge variant="light-warning"
                                        >≥100%
                                    </b-badge>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>

        <b-row>
            <!-- 室内环境 -->
            <b-col cols="6">
                <b-card
                    style="height: 30vh; max-height: 310px"
                    class="mb-1 cardBackdrop"
                >
                    <b-card-title>
                        室内环境
                        <small>Indoor Environment</small>
                    </b-card-title>

                    <b-card-text style="height: calc(100% - 3rem)">
                        <b-row class="h-100">
                            <b-col cols="4">
                                <statistic-card-with-area-chart
                                    v-if="chart_refresh"
                                    :chart-data="subscribersGained.series"
                                    :statistic="
                                        kFormatter(
                                            subscribersGained.analyticsData
                                                .subscribers
                                        )
                                    "
                                    icon="ThermometerIcon"
                                    style="height: 100%"
                                    statistic-title="温度 (℃)"
                                />
                                <div style="position: absolute; bottom: 0">
                                    <small class="mr-50">国标范围</small>
                                    <b-badge variant="light-primary"
                                        >20℃~26℃
                                    </b-badge>
                                </div>
                            </b-col>

                            <b-col cols="4">
                                <statistic-card-with-area-chart
                                    v-if="chart_refresh"
                                    :chart-data="quarterlySales.series"
                                    :statistic="
                                        kFormatter(
                                            quarterlySales.analyticsData.sales
                                        )
                                    "
                                    color="warning"
                                    icon="DropletIcon"
                                    style="height: 100%"
                                    statistic-title="湿度 (%)"
                                />
                                <div style="position: absolute; bottom: 0">
                                    <small class="mr-50">国标范围</small>
                                    <b-badge variant="light-warning"
                                        >30%~60%
                                    </b-badge>
                                </div>
                            </b-col>

                            <b-col cols="4">
                                <statistic-card-with-area-chart
                                    v-if="chart_refresh"
                                    :chart-data="carbonDioxide.series"
                                    :statistic="
                                        kFormatter(
                                            carbonDioxide.analyticsData.co2
                                        )
                                    "
                                    color="success"
                                    icon="RadioIcon"
                                    style="height: 100%"
                                    statistic-title="CO2 (ppm)"
                                />
                                <div style="position: absolute; bottom: 0">
                                    <small class="mr-50">国标范围</small>
                                    <b-badge variant="light-success"
                                        >≤900 ppm
                                    </b-badge>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>

            <!-- 能源消耗 -->
            <b-col cols="6">
                <b-card
                    style="height: 30vh; max-height: 310px"
                    class="mb-1 cardBackdrop"
                >
                    <b-card-title>
                        能耗分析
                        <small>Energy Analysis</small>
                    </b-card-title>

                    <b-card-text style="height: calc(100% - 3rem)">
                        <b-row class="h-100">
                            <b-col cols="5">
                                <vue-apex-charts
                                    v-if="chart_refresh"
                                    :options="earningsChart.chartOptions"
                                    :series="earningsChart.series"
                                    height="100%"
                                />
                            </b-col>

                            <b-col
                                class="d-flex flex-column justify-content-between"
                                cols="7"
                            >
                                <vue-apex-charts
                                    id="revenue-report-chart"
                                    v-if="chart_refresh"
                                    :options="revenueReport.chartOptions"
                                    :series="revenueReport.series"
                                    height="100%"
                                    type="line"
                                />
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import StatisticCardWithAreaChart from "@/@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import { $themeColors } from "@themeConfig";
import { kFormatter } from "@/@core/utils/filter";
import { onMounted, ref } from "@vue/composition-api";
import { setResize } from "@/libs/forge/utils/tools";
import VueApexCharts from "vue-apexcharts";
import {
    getHours48Params,
    getHours48Energy,
    getRealTimeEnergy,
} from "@/api/lfgc5";

export default {
    name: "NearlyZero",
    components: {
        StatisticCardWithAreaChart,
        VueApexCharts,
    },
    props: {
        data: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            project_id: 76,
            chart_refresh: true,
            refresh_interval: null,
            latest_time: null,
            total_cost: 0,
            increase_total_cost: 0,
            total_generate: 0,
            increase_total_generate: 0,
            total_co2: 0,
            increase_total_co2: 0,
            subscribersGained: {
                series: [
                    {
                        name: "室内温度",
                        data: [28, 40, 36, 36, 38, 44, 35],
                    },
                ],
                analyticsData: {
                    subscribers: 26,
                },
            },
            quarterlySales: {
                series: [
                    {
                        name: "室内湿度",
                        data: [10, 15, 7, 12, 3, 16, 21],
                    },
                ],
                analyticsData: {
                    sales: 50,
                },
            },
            energyConsumption: {
                series: [
                    {
                        name: "Energy",
                        data: [18, 30, 32, 46, 28, 14, 25],
                    },
                ],
                analyticsData: {
                    energy: 35,
                },
            },
            carbonDioxide: {
                series: [
                    {
                        name: "室内CO2",
                        data: [300, 200, 220, 160, 180, 240, 150],
                    },
                ],
                analyticsData: {
                    co2: 260,
                },
            },
            gaugeOptions: {
                radius: 0.9,
                range: {
                    color: "#30BF78",
                },
                indicator: {
                    pointer: {
                        style: {
                            stroke: "#D0D0D0",
                        },
                    },
                    pin: {
                        style: {
                            stroke: "#D0D0D0",
                        },
                    },
                },
                statistic: {
                    content: {
                        style: {
                            fontSize: 16,
                        },
                        formatter: ({ percent }) =>
                            `得分: ${(percent * 100).toFixed(0)}`,
                    },
                },
                gaugeStyle: {
                    lineCap: "round",
                },
            },
            earningsChart: {
                series: [53, 16, 31, 23],
                chartOptions: {
                    chart: {
                        type: "donut",
                        toolbar: {
                            show: false,
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    legend: {
                        show: true,
                        position: "bottom",
                        labels: {
                            colors: "#808695",
                        },
                    },

                    labels: ["空调外机", "照明", "空调内机+插座", "其他"],
                    stroke: { width: 0 },

                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    name: {
                                        offsetY: 20,
                                    },
                                    value: {
                                        offsetY: -20,
                                        color: "#808695",
                                    },
                                    total: {
                                        show: true,
                                        label: "总计",
                                        color: "#808695",
                                        formatter(w) {
                                            let text =
                                                w.globals.seriesTotals.reduce(
                                                    (a, b) => {
                                                        return a + b;
                                                    },
                                                    0
                                                );
                                            return text.toFixed(2);
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },
            revenueReport: {
                series: [
                    {
                        name: "发电量",
                        data: [
                            95, 177, 284, 256, 105, 63, 168, 218, 72, 298, 178,
                            80,
                        ],
                    },
                    {
                        name: "耗电量",
                        data: [
                            95, 177, 284, 256, 105, 63, 168, 218, 72, 298, 178,
                            80,
                        ],
                    },
                ],
                chartOptions: {
                    chart: {
                        stacked: false,
                        type: "line",
                        toolbar: { show: false },
                    },
                    grid: {
                        yaxis: {
                            lines: { show: false },
                        },
                    },
                    stroke: {
                        curve: "smooth",
                        width: 2.5,
                    },
                    xaxis: {
                        categories: [
                            "一月",
                            "二月",
                            "三月",
                            "四月",
                            "五月",
                            "六月",
                            "七月",
                            "八月",
                            "九月",
                            "十月",
                            "十一月",
                            "十二月",
                        ],
                        labels: {
                            style: {
                                colors: "#808695",
                            },
                        },
                        axisTicks: {
                            show: false,
                        },
                        axisBorder: {
                            show: false,
                        },
                    },
                    title: {
                        show: false,
                    },
                    legend: {
                        show: true,
                        position: "top",
                        labels: {
                            colors: "#808695",
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    colors: [$themeColors.primary, $themeColors.warning],
                    plotOptions: {
                        bar: {
                            columnWidth: "35%",
                            borderRadius: 6,
                        },
                        distributed: true,
                    },
                    yaxis: {
                        labels: {
                            style: {
                                colors: "#808695",
                            },
                        },
                    },
                },
            },
        };
    },
    watch: {},
    methods: {
        kFormatter,
        async getData() {
            this.chart_refresh = false;
            await getHours48Params({ project_id: this.project_id }).then(
                (res) => {
                    this.subscribersGained.series[0].data = res.data.temp_list;
                    this.subscribersGained.analyticsData.subscribers =
                        res.data.temp_current || NaN;
                    this.quarterlySales.series[0].data = res.data.hum_list;
                    this.quarterlySales.analyticsData.sales =
                        res.data.hum_current || NaN;
                    this.carbonDioxide.series[0].data = res.data.co2_list;
                    this.carbonDioxide.analyticsData.co2 =
                        res.data.co2_current || NaN;
                }
            );

            await getHours48Energy({ project_id: this.project_id }).then(
                (res) => {
                    this.latest_time = res.data.latest_time;
                    this.total_cost = res.data.total_cost;
                    this.total_generate = res.data.total_generate;
                    this.total_co2 = res.data.total_co2;
                    this.earningsChart.series = res.data.pie_data;
                    this.revenueReport.series = [
                        {
                            name: "发电量",
                            data: res.data.energy_list.generate,
                        },
                        {
                            name: "耗电量",
                            data: res.data.energy_list.cost,
                        },
                    ];
                    this.revenueReport.chartOptions.xaxis.categories =
                        res.data.energy_list.time;
                }
            );

            getRealTimeEnergy().then((res) => {
                this.total_co2 = res.data.co2;
                this.increase_total_co2 = res.data.increase_co2;
                this.total_cost = res.data.produce;
                this.increase_total_cost = res.data.increase_decrease_produce;
                this.total_generate = res.data.consume;
                this.increase_total_generate =
                    res.data.increase_decrease_consume;
                console.log(res, "resresresssss");
            });

            this.chart_refresh = true;
        },
    },
    async mounted() {
        await this.getData();
        setResize(this);
        if (this.refresh_interval !== null) {
            clearInterval(this.refresh_interval);
            this.refresh_interval = null;
        }
        this.refresh_interval = setInterval(() => {
            this.getData();
            setResize(this);
        }, 60000);
    },
};
</script>

<style lang="scss" scoped>
.adsk-viewing-viewer {
    background: none !important;
}

.homeViewWrapper {
    display: none !important;
}

.carouselSlide {
    height: 21rem;
}

.headerTitle {
    position: fixed;
    z-index: 20;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 36rem;
    letter-spacing: 0.5rem;
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem;
    box-shadow: none !important;
    border-bottom: 2px solid rgba(158, 158, 158, 0.5);

    .dark-layout & {
        border-bottom: 2px solid rgba(24, 144, 255, 0.5);
    }
}

.cardBackdrop {
    background: rgba(248, 248, 248, 0.5);
    backdrop-filter: blur(0.5rem);

    .dark-layout & {
        background: rgba(22, 29, 49, 0.5);
        backdrop-filter: blur(0.5rem);
    }
}
</style>
